import KomponenBase from "./komponenBase.js"

const modelName = "komponen";
const modelPath = null;

class Diagnosis extends KomponenBase {
  constructor(overrideName) {
    let model = { grup: null, skdi: null, sub_departemen: null };
    let requiredFields = ["skdi", "sub_departemen"];
    let ovrName = overrideName ? overrideName : modelName;
    super(ovrName, model, requiredFields, ["grup"], modelPath);
  }
}

export default Diagnosis;